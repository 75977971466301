import useAreas from 'data/areas/hooks/useAreas';

import useStationsById from './useStationsById';

function useStations(): Station[] {
  const { selected: area } = useAreas();

  const results = useStationsById(area?.stations || []);

  return Object.values(results.stations);
}

export default useStations;
