import { Array, Optional, Record, String } from 'runtypes';
import { parseISO } from 'date-fns';

import { getNonApi } from 'utils/fetch';

const WhatsNewRecord = Record({
  date: String,
  image: Optional(String),
  title: String,
  text: String,
});

const WhatsNewResult = Array(WhatsNewRecord);

export function fetchWhatsNew(url: string): Promise<WhatsNew[]> {
  return getNonApi(url)
    .then((data) => WhatsNewResult.check(data))
    .then((data) => data.map((item) => ({ ...item, date: parseISO(item.date) })));
}
