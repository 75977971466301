class Warning extends Error {
  name: string;

  constructor(message: string, name = 'Warning') {
    super(message);

    this.name = name;
  }
}

export default Warning;
