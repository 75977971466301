export enum AppUnits {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export enum Units {
  MM = 'mm',
  CM = 'cm',
  M = 'm',
  KM = 'km',
  IN = 'in',
  MI = 'mi',
  FT = 'ft',
}
