import { lazy, Suspense, useContext } from 'react';

import PageSettingsContext from 'modules/App/PageSettingsContext';

const ConsentBar = lazy(() => import('./ConsentBar'));

function LoadableConsentBar() {
  const {
    value: { disableConsent },
  } = useContext(PageSettingsContext);

  if (disableConsent) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <ConsentBar />
    </Suspense>
  );
}

export default LoadableConsentBar;
