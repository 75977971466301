import { stringify } from 'query-string';

import FetchError from 'errors/FetchError';

const DEFAULT_API_ROOT = process.env.REACT_APP_INTOTO_API;

type PlainType = string | number | boolean | undefined;

export function createUrl(
  url: string,
  params?: Record<string, PlainType | PlainType[]>,
  root = DEFAULT_API_ROOT,
): string {
  const base = `${root}${url}`;

  if (!params) {
    return base;
  }

  return [base, stringify(params)].join('?');
}

export function get(url: string, params?: Record<string, PlainType | PlainType[]>, root?: string): Promise<unknown> {
  return fetch(createUrl(url, params, root), { keepalive: true }).then((response) =>
    response.json().then((data) => {
      if (response.status !== 200) {
        throw new FetchError(data.message, response.status, response.statusText);
      }

      return data;
    }),
  );
}

export function getNonApi(url: string, params?: Record<string, PlainType | PlainType[]>): Promise<unknown> {
  return get(url, params, '');
}

export function retry(count: number, error: FetchError): boolean {
  if (error.status === 404) {
    return false;
  }

  return count < 3;
}
