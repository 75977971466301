import { ReactElement, ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';

interface PageSettingsValue {
  disableFooter: boolean;
  disableHeader: boolean;
  disableLogo?: boolean;
  disableMenu?: boolean;
  disableConsent?: boolean;
  metaTitle?: string;
  arrowLogo?: ReactElement;
  headerLogo?: ReactElement;
  headerTitle?: string;
  headerSubtitle?: string;
  headerElevation?: boolean;
  headerExtraContent?: ReactElement;
}

interface PageSettingsContextValue {
  value: PageSettingsValue;
  setValue: (newValue: Partial<PageSettingsValue>) => void;
  isInitialized: boolean;
}

const defaultValue: PageSettingsValue = {
  disableFooter: false,
  disableHeader: false,
  disableLogo: false,
  disableMenu: false,
  disableConsent: false,
  headerElevation: true,
};

const PageSettingsContext = createContext<PageSettingsContextValue>({
  value: defaultValue,
  setValue: () => undefined,
  isInitialized: false,
});

export function PageSettingsContextProvider({ children }: { children?: ReactNode }) {
  const [value, setValue] = useState<PageSettingsValue>(defaultValue);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const titleBase = 'Intoto';
    document.title = value.metaTitle ? `${value.metaTitle} - ${titleBase}` : titleBase;
  }, [value.metaTitle]);

  const setValueAndInitialize = useCallback(
    (newValue: Partial<PageSettingsValue>) => {
      setValue((current) => ({
        ...current,
        ...newValue,
      }));

      if (!isInitialized) {
        setIsInitialized(true);
      }
    },
    [isInitialized],
  );

  const contextValue = useMemo(
    () => ({
      value,
      setValue: setValueAndInitialize,
      isInitialized,
    }),
    [value, setValueAndInitialize, isInitialized],
  );

  return <PageSettingsContext.Provider value={contextValue}>{children}</PageSettingsContext.Provider>;
}

export default PageSettingsContext;
