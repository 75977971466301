import { useEffect, useState } from 'react';

function measureHeight(): number {
  return window.innerHeight;
}

function use100vh() {
  const [height, setHeight] = useState<number>(measureHeight);

  useEffect(() => {
    function setMeasuredHeight() {
      setHeight(measureHeight());
    }

    window.addEventListener('resize', setMeasuredHeight);

    return () => window.removeEventListener('resize', setMeasuredHeight);
  }, []);

  return height;
}

export default use100vh;
