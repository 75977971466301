import { CSSProperties, ReactNode } from 'react';

import use100vh from './use100vh';

interface FullHeightContainerProps {
  children?: ReactNode;
}

function FullHeightContainer({ children }: FullHeightContainerProps) {
  const height = use100vh();

  const style: CSSProperties = {
    height,
    display: 'flex',
    flexDirection: 'column',
  };

  return <div style={style}>{children}</div>;
}

export default FullHeightContainer;
