import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import useAppStorage from 'data/app/useAppStorage';

function Maps() {
  const { lang } = useAppStorage();

  if (lang === 'nb') {
    return (
      <Box>
        <Typography variant="subtitle2">Lokasjoner i kart</Typography>
        <Typography variant="body1" my={1}>
          {`Markørene i kartet representerer ulike lokasjoner og avlesninger. Klikk på
            markørene for å komme til lokasjonens egen side.`}
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          Verdier i markørene
        </Typography>
        <Typography variant="body1" my={1}>
          {`Som standard vises locasjonene med deres respektive endringer av
            vannivå siste 24 timer.`}
        </Typography>
        <Typography variant="body1" my={1}>
          Du kan justere filter for visningen på bunnen av sidene.
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          Fargeindikatorer
        </Typography>
        <Typography variant="body1" my={1}>
          {`Tydelig Intoto-blå betyr stabile forhold med lite endringer i vannivå.
          Lyseblå betyr merkbar nedgang, og rosa betyr merkbar økning.`}
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          Endre tidsomfang og målinger
        </Typography>
        <Typography variant="body1" my={1}>
          Du kan endre tidsrom på målinger fra endringer siste 24 timer til siste time.
        </Typography>
        <Typography variant="body1" my={1}>
          Du kan endre fra vannivå, til temperatur eller pH.
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          Flytt seg rundt på kart
        </Typography>
        <Typography variant="body1" my={1}>
          {`Du kan klikke og dra i kartet for å bevege deg rundt. Scroll for å forstørre
          eller forminske, eller bruk kontrollene nede til høyre på kartet.`}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="subtitle2">Locations on the map</Typography>
      <Typography variant="body1" my={1}>
        {`The markers on the map indicate station locations and their respective reading. 
        Click on one of the markers to navigate to their detailed information page.`}
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Values in the markers
      </Typography>
      <Typography variant="body1" my={1}>
        {`By default the map shows locations / stations and their respective water level
        change the last 24 hours.`}
      </Typography>
      <Typography variant="body1" my={1}>
        You can change the type of observation in the filter bar on the bottom of the page.
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Water level colour indications
      </Typography>
      <Typography variant="body1" my={1}>
        {`Values in dark blue are stable, light blue means the level lowered too much, red 
        means water level raised too much.`}
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Changing the observation type and duration
      </Typography>
      <Typography variant="body1" my={1}>
        {`You can change the observation type of a station to water level, water temperature,
        or pH. You'll find these options in the bottom bar called the filter bar.`}
      </Typography>
      <Typography variant="body1" my={1}>
        You can also change the time span of the change / average to 1 hour or 24 hours.
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Controlling the map
      </Typography>
      <Typography variant="body1" my={1}>
        {`You can click and drag the map to move around the map. Scroll to zoom in and out, or
        use the controls in the bottom right of the screen.`}
      </Typography>
    </Box>
  );
}

export default Maps;
