import useAreas from 'data/areas/hooks/useAreas';

import useStationsById from './useStationsById';
import useStationsStorage from './useStationsStorage';

function useVisitedStations(limit = -1): Station[] {
  const { selected: area } = useAreas();
  const { visited } = useStationsStorage();

  const toFind = visited.filter((id) => area?.stations.includes(id)).filter((_, i) => limit === -1 || i < limit);

  const { stations } = useStationsById(toFind);

  return toFind.map((id) => stations[id]).filter((s): s is Station => typeof s !== 'undefined');
}

export default useVisitedStations;
