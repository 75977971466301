import { useMemo } from 'react';
import { useQueries } from 'react-query';

import FetchError from 'errors/FetchError';

import { retry } from 'utils/fetch';

import { fetchStation } from '../api';

interface UseStationsResponse {
  isLoading: boolean;
  stations: Record<string, Station>;
  error?: Error;
}

function useStationsById(ids: string[]): UseStationsResponse {
  const queries = ids.map((id) => ({
    queryKey: ['station', id],
    queryFn: () => fetchStation(id).then((station) => ({ id, station })),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry,
  }));

  const results = useQueries<{ id: string; station: Station }, FetchError>(queries);

  const stations = useMemo(
    () =>
      results.reduce((acc: Record<string, Station>, station) => {
        if (!station.isSuccess) {
          return acc;
        }

        return {
          ...acc,
          [station.data.id]: station.data.station,
        };
      }, {}),
    [results],
  );

  return {
    isLoading: results.some((r) => r.isLoading) || results.some((r) => !r.isFetched),
    stations,
    error: results.find((r) => r.error)?.error || undefined,
  };
}

export default useStationsById;
