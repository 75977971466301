import { useQuery } from 'react-query';

import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import useAppStorage from 'data/app/useAppStorage';

import useIsSmall from 'hooks/useIsSmall';

import useTranslate from '../../hooks/useTranslate';

import { fetchNotice } from './api';

function Notice() {
  const { lang } = useAppStorage();
  const isSmall = useIsSmall();
  const t = useTranslate();

  const url = lang === 'en' ? process.env.REACT_APP_NOTICE_EN : process.env.REACT_APP_NOTICE_NB;

  const { isLoading, error, data } = useQuery<Notice | undefined, Error>(['notice', lang], () => {
    if (!url) {
      return Promise.resolve(undefined);
    }

    return fetchNotice(url);
  });

  if (isSmall || isLoading || error || !data) {
    return null;
  }

  return (
    <Alert
      severity="info"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        backgroundColor: 'grey.50',
      }}
    >
      <Box component="section" sx={{ display: 'flex' }}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: data.text }} />
        {data.url && (
          <>
            &nbsp;
            <Link target="_blank" href={data.url}>
              {t('read_more')}
            </Link>
          </>
        )}
      </Box>
    </Alert>
  );
}

export default Notice;
