import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import LogoArrow from 'components/Logo/LogoArrow';

import useTranslate from '../hooks/useTranslate';

function Copyright() {
  const t = useTranslate();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <LogoArrow size={18} />
      <Link href="https://intoto.io/" rel="noreferrer" underline="hover" sx={{ ml: 1 }}>
        {t('copyright', { year: new Date().getFullYear() })}
      </Link>
    </Box>
  );
}

export default Copyright;
