/* eslint-disable import/no-duplicates */
import nb from 'date-fns/locale/nb';
import enUS from 'date-fns/locale/en-US';

function getDateLocale(locale = 'en'): Locale {
  if (locale === 'nb') {
    return nb;
  }

  return enUS;
}

export default getDateLocale;
