import './loading.css';

export interface LoadingProps {
  size?: number;
  text?: string;
}

function Loading({ size = 150, text }: LoadingProps) {
  return (
    <div className="loading">
      <div className="loading-container">
        <div className="circle" style={{ width: size, height: size, marginBottom: text ? '1em' : undefined }}>
          <div className="wave" />
        </div>
        {text}
      </div>
    </div>
  );
}

export default Loading;
