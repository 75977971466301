import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import SearchIcon from '@mui/icons-material/Search';

import useStations from 'data/stations/hooks/useStations';
import useVisitedStations from 'data/stations/hooks/useVisitedStations';

import useIsSmall from 'hooks/useIsSmall';
import useCreatePath from 'hooks/useCreatePath';

import removeDiacritics from 'utils/removeDiacritics';
import formatStationName from 'utils/formatStationName';

import useTranslate from './useTranslate';
import SearchResultItem from './SearchResultItem';

interface SearchModalProps {
  isOpen: boolean;
  onClose(): void;
}

function SearchModal({ isOpen, onClose }: SearchModalProps) {
  const lastVisitedLimit = 10;
  const createPath = useCreatePath();

  const t = useTranslate();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const isSmall = useIsSmall();

  const allStations = useStations();
  const visitedStations: Station[] = useVisitedStations(lastVisitedLimit);

  const { refetch, data } = useQuery('search', () =>
    allStations.filter((s) => {
      if (search.trim() === '') {
        return false;
      }

      const name = removeDiacritics(formatStationName(s).toLowerCase().trim());

      return name.match(new RegExp(removeDiacritics(search), 'i'));
    }),
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      refetch().then(() => clearTimeout(timeout));
    }, 50);

    return () => clearTimeout(timeout);
  }, [refetch, search]);

  const filteredStations = data;

  const closeModal = useCallback(() => {
    onClose();
    setSearch('');
  }, [onClose]);

  const navigateToStation = useCallback(
    (station: Station) => {
      navigate(createPath(`locations/${station.id}`));
      closeModal();
    },
    [navigate, createPath, closeModal],
  );

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          mt: isSmall ? 2 : 6,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '90%',
          maxWidth: 600,
        }}
      >
        <Paper elevation={8}>
          <Box sx={{ p: 2, pb: 0 }}>
            <TextField
              id="search"
              fullWidth
              placeholder={t('placeholder_active')}
              autoFocus
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </Box>
          <List sx={{ maxHeight: 400, overflowY: 'auto' }}>
            {filteredStations && search.trim() !== '' && filteredStations.length === 0 && (
              <Typography variant="body2" sx={{ m: 2 }}>
                {t('no_options', { search })}
              </Typography>
            )}
            {filteredStations &&
              filteredStations.map((s) => <SearchResultItem key={s.id} station={s} navigate={navigateToStation} />)}
            {!filteredStations || filteredStations.length === 0
              ? visitedStations
                  .filter((s, i) => i < lastVisitedLimit)
                  .map((s) => <SearchResultItem type="visited" key={s.id} station={s} navigate={navigateToStation} />)
              : null}
          </List>
        </Paper>
      </Box>
    </Modal>
  );
}

export default SearchModal;
