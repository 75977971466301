import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import SearchToggle from 'components/Search/SearchToggle';
import Logo from 'components/Logo/Logo';

import useUser from 'data/users/hooks/useUser';
import useIsSmall from 'hooks/useIsSmall';
import useAreas from 'data/areas/hooks/useAreas';

import PageSettingsContext from 'modules/App/PageSettingsContext';

import Notice from './components/Notice/Notice';
import AppMenu from './components/AppMenu/AppMenu';

function Header() {
  const { requireAuth } = useUser();
  const isSmall = useIsSmall();
  const location = useLocation();
  const { isSubDirMode, subdirectory } = useAreas();

  const { value, isInitialized } = useContext(PageSettingsContext) ?? { value: {}, isInitialized: false };

  const {
    headerTitle = '',
    headerSubtitle = '',
    headerExtraContent = null,
    headerElevation = true,
    disableLogo = false,
    disableMenu = false,
    disableHeader = false,
  } = value;

  // Updated function to determine if it's the homepage
  const isHomePage = () => {
    const isRoot = location.pathname === '/';
    const isSubdirectoryRoot = isSubDirMode && subdirectory && location.pathname === `/${subdirectory}/`;
    return isRoot || isSubdirectoryRoot;
  };

  // Set fullWidth based on whether it's the homepage
  const fullWidth = isHomePage();

  if (!isInitialized || disableHeader) return null;

  const hideMenu = disableMenu || requireAuth;

  return (
    <>
      <Notice />
      <div style={{ paddingTop: isSmall ? 50 : 0 }}>
        <Card
          elevation={headerElevation ? 9 : 0}
          sx={{
            position: {
              xs: 'fixed',
              sm: 'static',
            },
            width: '100%',
            height: {
              xs: 50,
              sm: 'auto',
            },
            top: 0,
            zIndex: (theme) => theme.zIndex.appBar,
          }}
        >
          <Container
            maxWidth={fullWidth ? false : 'lg'}
            disableGutters={Boolean(fullWidth)}
            sx={{
              my: isSmall ? 0 : 1,
              flexGrow: 1,
              '& .MuiToolbar-gutters': {
                px: fullWidth ? { xs: 2, sm: 3 } : 0,
              },
            }}
          >
            <Toolbar variant={isSmall ? 'dense' : 'regular'}>
              <Box
                sx={{
                  display: 'block',
                  flexShrink: 0,
                }}
              >
                <Logo
                  disableLogo={disableLogo}
                  isMobile={isSmall}
                  title={headerTitle}
                  subtitle={headerSubtitle}
                  extraContent={headerExtraContent}
                />
              </Box>
              <Box
                className="hide-on-print"
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {!hideMenu && (
                  <Box sx={{ mr: 1, color: 'text.secondary' }}>
                    <SearchToggle />
                  </Box>
                )}
                {!isSmall && <Divider sx={{ mr: 1, ml: 2 }} orientation="vertical" flexItem />}
                {!disableMenu && <AppMenu isMobile={isSmall} />}
              </Box>
            </Toolbar>
          </Container>
        </Card>
      </div>
    </>
  );
}

export default Header;
