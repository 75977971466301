import { Link } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonIcon from '@mui/icons-material/Person';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';

import useUser from 'data/users/hooks/useUser';

import useAppTranslate from 'locales/useAppTranslate';

import { loginPath } from 'utils/loginPath';

interface UserMenuProps {
  onCloseMenu: () => void;
}

function UserMenu({ onCloseMenu }: UserMenuProps) {
  const t = useAppTranslate();
  const { isLoading, isLoggedIn, logout, requireAuth } = useUser();

  const signOut = () => {
    logout();
    onCloseMenu();
  };

  if (isLoading) {
    return (
      <List>
        <ListItemButton>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={t('loading_user')} />
        </ListItemButton>
      </List>
    );
  }

  if (isLoggedIn) {
    return (
      <List>
        <ListItemButton onClick={signOut}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={t('logout')} />
        </ListItemButton>
      </List>
    );
  }

  if (requireAuth) {
    return (
      <List>
        <ListItemButton onClick={onCloseMenu} component={Link} to={loginPath()}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={t('login')} />
        </ListItemButton>
      </List>
    );
  }

  return null;
}

export default UserMenu;
