import Box from '@mui/material/Box';

import arrow from './intoto-arrow.svg';

interface LogoArrowProps {
  src?: string;
  size?: number;
  title?: string;
}

function LogoArrow({ size = 32, title = '', src = arrow }: LogoArrowProps) {
  if (!src) return null;

  return <Box component="img" width={size} height={size} src={src} title={title} alt="Intoto" />;
}

export default LogoArrow;
