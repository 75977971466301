import { ChangeEvent, useCallback } from 'react';

import { NativeSelect } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import useAppStorage from 'data/app/useAppStorage';

import { AppUnits } from 'types/intoto-studio/units';

import useTranslate from '../../hooks/useTranslate';

export interface UnitSwitcherProps {
  isMobile?: boolean;
}

function UnitSwitcher({ isMobile }: UnitSwitcherProps) {
  const t = useTranslate();

  const { units, setUnits } = useAppStorage();

  const handleUnitsChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;

      if (value === AppUnits.IMPERIAL || value === AppUnits.METRIC) {
        setUnits(value);
      }
    },
    [setUnits],
  );

  const metric = t('metric');
  const imperial = t('imperial');

  return (
    <FormControl
      sx={{
        width: '100%',
        minWidth: isMobile ? '53px' : '120px',
      }}
    >
      <NativeSelect
        value={units}
        onChange={handleUnitsChange}
        inputProps={{
          name: 'units',
          id: 'units-switcher',
        }}
      >
        <option value={AppUnits.METRIC}>{metric}</option>
        <option value={AppUnits.IMPERIAL}>{imperial}</option>
      </NativeSelect>
    </FormControl>
  );
}

export default UnitSwitcher;
