import { createContext, ReactNode, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { retry } from 'utils/fetch';
import isSubDirMode from 'utils/isSubDirMode';

import FetchError from 'errors/FetchError';
import { fetchAreas } from './api';

const DEFAULT_AREA = !isSubDirMode ? process.env.REACT_APP_SELECTED_AREA || '1' : undefined;

interface Area {
  id: string;
  name: string;
  stations: string[];
  coordinates?: [number, number];
  zoom?: number;
  icon?: string;
  logo?: string;
  title?: string;
  title_about?: string;
  intro?: string;
  intro_url?: string;
}

interface AreasContextProps {
  isLoading: boolean;
  isFetched: boolean;
  error: FetchError | null;
  mapped: Record<string, Area>;
  isSubDirMode: boolean;
  subdirectory?: string;
  selected?: Area;
  setSubdirectory: (subdirectory: string | undefined) => void;
}

export const AreasContext = createContext<AreasContextProps>({
  isLoading: false,
  isFetched: false,
  isSubDirMode: false,
  error: null,
  mapped: {},
  setSubdirectory: () => undefined,
});

function AreasContextProvider({ children }: { children: ReactNode }) {
  const [subdirectory, setSubdirectory] = useState<string | undefined>(undefined);

  const {
    data: areas,
    isLoading,
    isFetched,
    error,
  } = useQuery<Area[], FetchError>('areas', fetchAreas, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry,
  });

  const selectedAreaId = useMemo(() => {
    if (!subdirectory || !areas) {
      return DEFAULT_AREA;
    }

    const area = areas.find((a) => a.name.toLowerCase().trim() === subdirectory);

    return area ? area.id : DEFAULT_AREA;
  }, [areas, subdirectory]);

  const mapped = useMemo(
    () =>
      areas?.reduce(
        (acc, area) => ({
          ...acc,
          [area.id]: area,
        }),
        {} as Record<string, Area>,
      ) || {},
    [areas],
  );

  const selected = useMemo(() => mapped[selectedAreaId || ''], [selectedAreaId, mapped]);

  const value = useMemo(
    () => ({
      isLoading,
      isFetched,
      isSubDirMode,
      subdirectory,
      error,
      mapped,
      selected,
      setSubdirectory,
    }),
    [isLoading, isFetched, error, mapped, selected, subdirectory, setSubdirectory],
  );

  return <AreasContext.Provider value={value}>{children}</AreasContext.Provider>;
}

export default AreasContextProvider;
