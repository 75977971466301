import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { Auth } from 'aws-amplify';

import type { ReactNode } from 'react';

import './setup';

export interface UserContextProps {
  isLoading: boolean;
  isLoggedIn: boolean;
  logout: () => void;
  signIn: (username: string, password: string) => Promise<boolean>;
  completePassword: (password: string) => Promise<void>;
  forgotPassword: (username: string) => Promise<void>;
  forgotPasswordSubmit: (username: string, code: string, password: string) => Promise<string>;
  user?: AWSUserResponse;
  error?: Error;
}

export const UserContext = createContext<UserContextProps>({
  isLoading: true,
  isLoggedIn: false,
  logout: () => undefined,
  signIn: () => Promise.resolve(false),
  completePassword: () => Promise.resolve(undefined),
  forgotPassword: () => Promise.resolve(undefined),
  forgotPasswordSubmit: () => Promise.resolve(''),
});

function Provider({ children }: { children?: ReactNode }) {
  const [user, setUser] = useState<AWSUserResponse>();
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    Auth.currentSession()
      .then((): Promise<AWSUserResponse> => Auth.currentAuthenticatedUser())
      .then((userData) => {
        setUser(userData);
      })
      .catch(() => setUser(undefined))
      .finally(() => setIsInitializing(false));
  }, []);

  const signIn = useCallback(
    (username: string, password: string): Promise<boolean> =>
      Auth.signIn(username, password).then((response: AWSUserResponse) => {
        setUser(response);

        return response.challengeName === 'NEW_PASSWORD_REQUIRED';
      }),
    [],
  );

  const completePassword = useCallback(
    (password: string): Promise<void> =>
      Auth.completeNewPassword(user, password).then((newUser: AWSUserResponse) => {
        setUser(newUser);
      }),
    [user],
  );

  const forgotPassword = useCallback((username: string): Promise<void> => Auth.forgotPassword(username), []);

  const forgotPasswordSubmit = useCallback(
    (username: string, code: string, password: string): Promise<string> =>
      Auth.forgotPasswordSubmit(username, code, password),
    [],
  );
  const logout = useCallback(() => {
    setUser(undefined);
    Auth.signOut();
  }, []);

  const value = useMemo(
    () => ({
      isLoading: isInitializing,
      isLoggedIn: !!user,
      signIn,
      completePassword,
      forgotPassword,
      forgotPasswordSubmit,
      user,
      logout,
    }),
    [isInitializing, user, signIn, completePassword, forgotPassword, forgotPasswordSubmit, logout],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default Provider;
