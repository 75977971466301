import { createContext, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

type SocketSubscriptionUpdate = (data: DataPoint) => void;

interface SocketContextProps {
  state: 'idle' | 'connecting' | 'connected' | 'error';
  subscribe: (seriesId: string, onUpdate: SocketSubscriptionUpdate) => () => void;
}

type Subscriptions = Record<string, Record<string, SocketSubscriptionUpdate>>;

interface SubscribeObservation {
  type: 'SUBSCRIBE_OBSERVATION';
  payload: {
    seriesId: string;
  };
}

interface UnsubscribeObservation {
  type: 'UNSUBSCRIBE_OBSERVATION';
  payload: {
    seriesId: string;
  };
}

interface ObservationUpdate {
  type: 'OBSERVATION_UPDATE';
  payload: {
    seriesId: string;
    data: Omit<DataPoint, 'date'> & {
      date: string;
      valid: boolean;
    };
  };
}

type SocketMessage = SubscribeObservation | UnsubscribeObservation;

export const SocketContext = createContext<SocketContextProps>({
  state: 'idle',
  subscribe: () => () => undefined,
});

function SocketContextProvider({ children }: { children?: ReactNode }) {
  const [state, setState] = useState<SocketContextProps['state']>('idle');
  const socket = useRef<WebSocket>();
  const subscriptions = useRef<Subscriptions>({});

  const wsUrl = process.env.REACT_APP_WEBSOCKET_SERVER;

  useEffect(() => {
    if (state === 'idle' && wsUrl) {
      setState('connecting');

      // connect to server
      socket.current = new WebSocket(wsUrl);

      // on message receive
      socket.current.onmessage = (event) => {
        const { data } = event;

        try {
          const message: ObservationUpdate = JSON.parse(data);

          const { type, payload } = message;

          if (type === 'OBSERVATION_UPDATE' && payload && payload.seriesId && payload.data && payload.data.valid) {
            // send update to subscribed members
            Object.values(subscriptions.current[payload.seriesId]).forEach((update) => {
              update(payload.data);
            });
          }
        } catch (e) {
          // silent failing
        }
      };

      // on success
      socket.current.onopen = () => {
        setState('connected');
      };

      // on close
      socket.current.onclose = () => {
        // reconnect on close
        setState('idle');
      };

      // on error
      socket.current.onerror = () => {
        setState('error');
      };
    }
  }, [state, wsUrl]);

  const sendMessage = useCallback((message: SocketMessage) => {
    if (socket.current) {
      try {
        socket.current.send(JSON.stringify(message));
      } catch (e) {
        // silent fail
      }
    }
  }, []);

  const subscribe: SocketContextProps['subscribe'] = useCallback(
    (seriesId, onUpdate) => {
      if (!subscriptions.current) {
        return () => undefined;
      }

      // create observations record if not exists
      if (!subscriptions.current[seriesId]) {
        subscriptions.current[seriesId] = {};

        // subscribe to observation when not observing yet
        sendMessage({
          type: 'SUBSCRIBE_OBSERVATION',
          payload: {
            seriesId,
          },
        });
      }

      const subId = (+new Date()).toString() + Math.round(Math.random() * 100000);

      // put seriesId in observation list
      subscriptions.current[seriesId][subId] = onUpdate;

      // return unsubscribe function
      return () => {
        delete subscriptions.current[seriesId][subId];

        // clean up after one second
        setTimeout(() => {
          // skip removal if it is removed already
          if (!subscriptions.current[seriesId]) {
            return;
          }

          // when no subs are left: unsubscribe from observation
          if (Object.keys(subscriptions.current[seriesId]).length === 0) {
            sendMessage({
              type: 'UNSUBSCRIBE_OBSERVATION',
              payload: {
                seriesId,
              },
            });

            delete subscriptions.current[seriesId];
          }
        }, 1000);
      };
    },
    [sendMessage],
  );

  const value = useMemo(
    () => ({
      state,
      subscribe,
      sendMessage,
    }),
    [sendMessage, state, subscribe],
  );

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
}

export default SocketContextProvider;
