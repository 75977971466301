import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import LocationOnIcon from '@mui/icons-material/LocationOn';

import useAppStorage from 'data/app/useAppStorage';

import useTranslate from '../../hooks/useTranslate';

interface LocationProps {
  onClick: () => void;
}

function Location({ onClick }: LocationProps) {
  const t = useTranslate();

  const { location, setLocation } = useAppStorage();

  const onResetLocation = () => {
    setLocation();
    onClick();
  };

  const onDetermineLocation = () => {
    onClick();
    navigator.geolocation.getCurrentPosition((position) => {
      setLocation([position.coords.latitude, position.coords.longitude]);
    });
  };

  return (
    <ListItemButton onClick={!location ? onDetermineLocation : onResetLocation}>
      <ListItemIcon>
        <LocationOnIcon />
      </ListItemIcon>
      <ListItemText>{location ? t('location.remove') : t('location.determine')}</ListItemText>
    </ListItemButton>
  );
}

export default Location;
