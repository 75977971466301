function pocData(station: IntotoStation): Promise<IntotoStation> {
  return import(`./mock/${station.id}/data.json`)
    .then((result: { default: Partial<IntotoStation> }) => ({
      ...station,
      ...result.default,
    }))
    .catch(() => station);
}

export default pocData;
