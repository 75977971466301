import { useCallback, useState } from 'react';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import useTranslate from '../../hooks/useTranslate';

import HelpModalContent from './HelpModalContent';

interface HelpSectionProps {
  onClick: () => void;
}

function HelpSection({ onClick }: HelpSectionProps) {
  const [isOpened, setIsOpened] = useState(false);
  const t = useTranslate();

  const handleOpen = useCallback(() => {
    onClick();
    setIsOpened(true);
  }, [onClick]);
  const handleClose = useCallback(() => setIsOpened(false), []);

  return (
    <>
      <Modal open={isOpened} onClose={handleClose}>
        <Box>
          <HelpModalContent onClose={handleClose} />
        </Box>
      </Modal>
      <ListItemButton onClick={handleOpen}>
        <ListItemIcon>
          <HelpOutlineIcon />
        </ListItemIcon>
        <ListItemText primary={t('help.menu_title')} />
      </ListItemButton>
    </>
  );
}

export default HelpSection;
