function formatStationName(station?: Station): string {
  if (!station) {
    return '';
  }

  const { name, location } = station;

  return [name, location].filter((i) => !!i).join(', ');
}

export default formatStationName;
