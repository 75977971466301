import { useState } from 'react';

import ListItemIcon from '@mui/material/ListItemIcon';
import Badge from '@mui/material/Badge';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import useTranslate from '../hooks/useTranslate';
import useWhatsNew from '../hooks/useWhatsNew';

import WhatsNewModal from './WhatsNewModal';

interface WhatsNewMenuItemProps {
  onClick: () => void;
}

function WhatsNewMenuItem({ onClick }: WhatsNewMenuItemProps) {
  const { newItemsCount } = useWhatsNew();
  const [isOpen, setIsOpen] = useState(false);
  const t = useTranslate();

  const handleOpen = () => {
    setIsOpen(true);
    onClick();
  };

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <ListItem button onClick={handleOpen}>
        <ListItemIcon>
          <Badge badgeContent={newItemsCount} color="error">
            <NewReleasesIcon />
          </Badge>
        </ListItemIcon>
        <ListItemText primary={t('title')} />
      </ListItem>
      <WhatsNewModal isOpen={isOpen} onClose={handleClose} />
    </>
  );
}

export default WhatsNewMenuItem;
