import { Boolean, Number, String, Array, Tuple, Record, Union, Null, Optional, Literal } from 'runtypes';

import { get } from 'utils/fetch';

import pocData from './pocData';
import pocStats from './pocStats';

const LatestRecord = Record({
  val: Number,
  max: Optional(Number),
  min: Optional(Number),
  mis: Optional(Boolean),
  d: Number,
  h: Number,
  date: String,
});

const SeriesRecord = Record({
  id: String,
  type: Union(Literal('water-level'), Literal('water-temperature'), Literal('water-ph')),
  online: Optional(Boolean),
  latest: Optional(Union(LatestRecord, Null)),
  trends: Optional(
    Union(
      Record({
        interval: Number,
        entities: Array(Number),
        start: String,
      }),
      Null,
    ),
  ),
});

const StationRecord = Record({
  id: String,
  name: String,
  online: Optional(Boolean),
  location: Union(String, Null),
  areas: Array(String),
  coordinates: Tuple(Number, Number),
  series: Array(SeriesRecord),
  hash: String,
  latest: Optional(Union(LatestRecord, Null)),
  // to be implemented on backend
  starred: Optional(Boolean),
  profile: Optional(
    Array(
      Record({
        x: Number,
        y: Number,
      }),
    ),
  ),
});

const StationResponse = Record({
  entity: StationRecord,
  message: String,
});

type LatestResult = {
  val: number;
  min?: number;
  max?: number;
  mis?: boolean;
  d: number;
  h: number;
  date: string;
};

function latestToDataPoint(latest: LatestResult | null | undefined): MissingDataPoint | DataPoint | undefined {
  if (!latest) {
    return undefined;
  }

  return {
    value: latest.val,
    date: latest.date,
    maxValue: latest.max,
    minValue: latest.min,
    missing: latest.mis ? true : undefined,
    change: {
      '1h': latest.h,
      '24h': latest.d,
    },
  };
}

export const fetchStation = (id: string): Promise<IntotoStation> =>
  get(`/station/${id}`).then((result) => {
    try {
      const stationResult = StationResponse.check(result);

      const station: IntotoStation = {
        ...stationResult.entity,
        provider: 'intoto',
        online: !!stationResult.entity.online,
        latest: latestToDataPoint(stationResult.entity.latest),
        series: stationResult.entity.series.map((s) => ({
          ...s,
          online: !!s.online,
          latest: latestToDataPoint(s.latest),
          trends: s.trends || undefined,
        })),
      };

      return pocData(station).then(pocStats);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Station failed to validate: ${id}`);
      throw err;
    }
  });
