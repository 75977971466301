import { createContext, ReactNode, useCallback, useMemo } from 'react';

import useAppStorageState from 'hooks/useAppStorageState';

import createAppStorage from 'utils/createAppStorage';

interface StationsState {
  visited: Station['id'][];
  starred: Station['id'][];
}

interface StationActions {
  starStation: (station: Station['id']) => void;
  unstarStation: (station: Station['id']) => void;
  visitStation: (station: Station['id']) => void;
}

const visitedStorage = createAppStorage<Station['id'][]>('intoto_visited_stations', [], {
  setter: ([station], visited) => [station, ...visited.filter((s) => s !== station)],
});
const starredStorage = createAppStorage<Station['id'][]>('intoto_fav_stations', []);

export const StationsStorageContext = createContext<StationsState & StationActions>({
  visited: visitedStorage.get(),
  starred: starredStorage.get(),
  starStation: () => undefined,
  unstarStation: () => undefined,
  visitStation: () => undefined,
});

function StationsStorageContextProvider({ children }: { children: ReactNode }) {
  const [visited, setVisited] = useAppStorageState(visitedStorage);
  const [starred, setStarred] = useAppStorageState(starredStorage);

  const visitStation = useCallback(
    (station: Station['id']) => {
      setVisited([station]);
    },
    [setVisited],
  );

  const starStation = useCallback(
    (station: Station['id']) => {
      setStarred([...starred.filter((s) => s !== station), station]);
    },
    [setStarred, starred],
  );

  const unstarStation = useCallback(
    (station: Station['id']) => {
      setStarred(starred.filter((s) => s !== station));
    },
    [setStarred, starred],
  );

  const value = useMemo(
    () => ({
      visited,
      starred,
      starStation,
      unstarStation,
      visitStation,
    }),
    [starStation, starred, unstarStation, visitStation, visited],
  );

  return <StationsStorageContext.Provider value={value}>{children}</StationsStorageContext.Provider>;
}

export default StationsStorageContextProvider;
