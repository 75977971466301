import { useState } from 'react';
import { format } from 'date-fns';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import Loading from 'components/Loading/Loading';
import ErrorDisplay from 'components/ErrorDisplay/ErrorDisplay';

import useAppStorage from 'data/app/useAppStorage';

import Warning from 'errors/Warning';

import useTranslate from '../hooks/useTranslate';
import useWhatsNew from '../hooks/useWhatsNew';

interface WhatsNewModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function WhatsNewModal({ isOpen, onClose }: WhatsNewModalProps) {
  const t = useTranslate();

  const [activeStep, setActiveStep] = useState(0);
  const { locale } = useAppStorage();

  const { isLoading, error, items } = useWhatsNew(activeStep, isOpen);

  const handleNext = () => setActiveStep(activeStep + 1);
  const handlePrevious = () => setActiveStep(activeStep - 1);

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onClose} scroll="paper" fullWidth maxWidth="sm">
      <IconButton
        className="WhatsNewModalClose"
        sx={{
          position: 'absolute',
          right: (theme) => theme.spacing(1),
          top: (theme) => theme.spacing(3),
        }}
        onClick={onClose}
        size="large"
      >
        <CloseIcon color="inherit" />
      </IconButton>
      {isLoading && (
        <Box sx={{ height: 300 }}>
          <Loading />
        </Box>
      )}
      {!isLoading && error && (
        <Box
          sx={{
            height: 300,
            padding: 2,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ErrorDisplay error={error} />
        </Box>
      )}
      {!isLoading && !error && items && (
        // this is not a useless fragment..
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {items.length > 0 ? (
            <>
              <DialogTitle>
                <Typography variant="h6">{t('title')}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {format(items[activeStep].date, 'PPP', { locale })}
                </Typography>
              </DialogTitle>
              <DialogContent dividers>
                {items[activeStep].image && (
                  <img
                    style={{
                      width: '100%',
                      display: 'block',
                    }}
                    alt={items[activeStep].title}
                    src={items[activeStep].image}
                  />
                )}
                <Box sx={{ padding: 2 }}>
                  <Typography variant="h6" sx={{ textAlign: 'center' }}>
                    {items[activeStep].title}
                  </Typography>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: items[activeStep].text }} />
                </Box>
              </DialogContent>
              <MobileStepper
                variant="dots"
                steps={items.length}
                position="static"
                activeStep={activeStep}
                className="WhatsNewNav"
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    className="WhatsNewNavNext"
                    disabled={activeStep === items.length - 1}
                  >
                    {t('next')}
                    <KeyboardArrowRightIcon />
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handlePrevious} className="WhatsNewNavPrev" disabled={activeStep === 0}>
                    <KeyboardArrowLeftIcon />
                    {t('previous')}
                  </Button>
                }
              />
            </>
          ) : (
            <ErrorDisplay error={new Warning(t('not_found'))} />
          )}
        </>
      )}
    </Dialog>
  );
}

export default WhatsNewModal;
