import { Array, Record, String, Optional, Tuple, Number } from 'runtypes';

import { get } from 'utils/fetch';

import pocData from './pocData';

const AreaRecord = Record({
  id: String,
  name: String,
  stations: Array(String),
  coordinates: Optional(Tuple(Number, Number)),
  zoom: Optional(Number),
});

const AreaResponse = Record({
  entities: Array(AreaRecord),
  message: String,
});

export const fetchAreas = (): Promise<Area[]> =>
  get('/area').then((results) => {
    const { entities: areas } = AreaResponse.check(results);

    const promises = areas.map((area) => pocData(area));

    return Promise.all(promises).then((areasWithPOC) => areasWithPOC);
  });
