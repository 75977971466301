import { createContext, ReactNode, useCallback, useEffect, useMemo } from 'react';
import i18next from 'i18next';

import useAppStorageState from 'hooks/useAppStorageState';

import createAppStorage from 'utils/createAppStorage';

import { AppUnits } from 'types/intoto-studio/units';

import getDateLocale from './getDateLocale';

export interface AppState {
  lang: AppLang;
  locale: Locale;
  units: AppUnits;
  location: Coordinates | undefined;
  cookieConsent: Consent;
  readItems: string[];
  intro: boolean;
}

interface AppActions {
  setCookieConsent: (consent: Consent) => void;
  setLang: (lang: AppLang) => void;
  setLocation: (coordinates?: Coordinates) => void;
  setReadItem: (item: string) => void;
  setUnits: (units: AppUnits) => void;
  setIntro: (isOpened: boolean) => void;
}

const consentStorage = createAppStorage<Consent>('intoto_consent', 'unknown');
export const langStorage = createAppStorage<AppLang>('intoto_lang', 'nb', {
  getter: (lang) => (lang === 'en' || lang === 'nb' ? lang : 'nb'),
  onSet: (nextLang) => i18next.changeLanguage(nextLang),
});
const locationStorage = createAppStorage<Coordinates | undefined>('intoto_location', undefined);
const whatsNewStorage = createAppStorage<string[]>('intoto_whats_new', [], {
  setter: ([newItem], readItems) => [...readItems.filter((item) => newItem !== item), newItem],
});
const unitsStorage = createAppStorage<AppUnits>('intoto_units', AppUnits.METRIC, {
  getter: (unit: string | null): AppUnits => {
    switch (unit) {
      case 'imperial':
        return AppUnits.IMPERIAL;
      default:
        return AppUnits.METRIC;
    }
  },
});
const introStorage = createAppStorage<boolean>('intoto_intro', false);

const initialState: AppState = {
  cookieConsent: consentStorage.get(),
  lang: langStorage.get(),
  locale: getDateLocale(),
  location: locationStorage.get(),
  readItems: whatsNewStorage.get(),
  units: unitsStorage.get(),
  intro: introStorage.get(),
};

const initialActions: AppActions = {
  setCookieConsent: () => undefined,
  setLang: () => undefined,
  setLocation: () => undefined,
  setReadItem: () => undefined,
  setUnits: () => undefined,
  setIntro: () => false,
};

export const AppStorageContext = createContext<AppState & AppActions>({
  ...initialState,
  ...initialActions,
});

export default function AppStorageContextProvider({ children }: { children: ReactNode }) {
  const [cookieConsent, setCookieConsent] = useAppStorageState(consentStorage);
  const [lang, setLang] = useAppStorageState(langStorage);
  const locale = useMemo(() => getDateLocale(lang), [lang]);
  const [location, setLocation] = useAppStorageState(locationStorage);
  const [readItems, setReadItems] = useAppStorageState(whatsNewStorage);
  const [units, setUnits] = useAppStorageState(unitsStorage);
  const [intro, setIntro] = useAppStorageState(introStorage);

  useEffect(() => {
    const htmlTag = document.querySelector('html');

    if (htmlTag) {
      htmlTag.setAttribute('lang', lang);
    }
  }, [lang]);

  const setReadItem = useCallback(
    (newItem: string) => {
      setReadItems([newItem]);
    },
    [setReadItems],
  );

  const contextValue = useMemo(
    () => ({
      cookieConsent,
      intro,
      lang,
      locale,
      location,
      readItems,
      setCookieConsent,
      setIntro,
      setLang,
      setLocation,
      setReadItem,
      setUnits,
      units,
    }),
    [
      cookieConsent,
      intro,
      lang,
      locale,
      location,
      readItems,
      setCookieConsent,
      setIntro,
      setLang,
      setLocation,
      setReadItem,
      setUnits,
      units,
    ],
  );

  return <AppStorageContext.Provider value={contextValue}>{children}</AppStorageContext.Provider>;
}
