import Amplify from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_ID,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_SRP_AUTH',
  },
});
