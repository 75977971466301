export interface AppStorage<T> {
  get(): T;
  set(value: T): T;
}

interface StorageSettings<T> {
  getter?: (raw: string | null) => T;
  setter?: (value: T, currentValue: T) => T;
  onSet?: (value: T) => void;
}

function createAppStorage<T = string>(key: string, defaultValue: T, options: StorageSettings<T> = {}): AppStorage<T> {
  const get = () => {
    const value = window.localStorage.getItem(key);

    try {
      if (options.getter) {
        return options.getter(value ? JSON.parse(value) : defaultValue);
      }

      return value ? JSON.parse(value) : defaultValue;
    } catch (e) {
      return defaultValue;
    }
  };

  return {
    get,
    set: (value: T) => {
      const newValue = options.setter ? options.setter(value, get()) : value;

      window.localStorage.setItem(key, JSON.stringify(newValue));

      if (options.onSet) {
        options.onSet(value);
      }

      return newValue;
    },
  };
}

export default createAppStorage;
