import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useAreas from 'data/areas/hooks/useAreas';

import useCreatePath from 'hooks/useCreatePath';

import LogoArrow from './LogoArrow';
import LogoText from './LogoText';

export interface LogoProps {
  disableLogo?: boolean;
  isMobile?: boolean;
  title?: string;
  subtitle?: string;
  extraContent?: ReactElement | null;
}

const version = process.env.REACT_APP_VERSION_HASH || 'develop';

window.intoto = () => {
  const p = document.getElementById('logo-pizza');
  if (p) p.style.display = 'block';
  return "🍕 It's a pizza party! 🍕";
};

function useAreaLogo(isMobile = false) {
  const { isSubDirMode, selected: { id, icon, logo, title } = {} } = useAreas();
  const { data, isLoading } = useQuery<string[]>(['logo', id], () => {
    if (!isSubDirMode || !id || !icon || !logo) {
      return Promise.resolve([]);
    }

    return Promise.all([
      import(`data/areas/mock/${id}/${icon}`).then((m) => m.default),
      import(`data/areas/mock/${id}/${logo}`).then((m) => m.default),
    ]);
  });

  return {
    isLoading,
    data:
      id && data
        ? [
            icon ? <LogoArrow size={!isMobile ? 64 : 32} src={data[0]} title={title} /> : undefined,
            logo ? <LogoText height={!isMobile ? 64 : 32} src={data[1]} /> : undefined,
          ]
        : [],
  };
}

function Logo({ title, subtitle, extraContent, isMobile = false, disableLogo = false }: LogoProps) {
  const createPath = useCreatePath();
  const logoLink = createPath('');
  const { isLoading, data } = useAreaLogo(isMobile);

  const [arrowLogo, headerLogo] = data;

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          flexGrow: 1,
          alignItems: 'center',
          display: 'flex',
          mr: 2,
        }}
        className="IntotoLogo"
      >
        <Box
          id="logo-pizza"
          sx={{
            display: 'none',
            position: 'absolute',
            top: '1px',
            left: isMobile ? '3px' : '4px',
            fontSize: isMobile ? '17px' : '21px',
            transform: 'rotate(41deg)',
            pointerEvents: 'none',
          }}
        >
          🍕
        </Box>
        <Box
          component={Link}
          to={logoLink}
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {!disableLogo && (arrowLogo || <LogoArrow size={isMobile ? 24 : 32} title={`Intoto v: ${version}`} />)}
          {!disableLogo && !title && !isMobile && (headerLogo || <LogoText />)}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {!isMobile && title && (
            <Typography
              variant="h5"
              component="h1"
              sx={{
                ml: disableLogo ? 0 : 2,
                mr: 1,
                fontWeight: 'bold',
              }}
            >
              {title}
            </Typography>
          )}
          {!isMobile && extraContent}
        </Box>
      </Box>
      {!isMobile && subtitle && (
        <Box sx={{ ml: 6 }}>
          <Typography variant="body1" sx={{ color: 'text.disabled' }}>
            {subtitle}
          </Typography>
        </Box>
      )}
    </>
  );
}

export default Logo;
