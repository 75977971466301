import { ReactNode } from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { SxProps } from '@mui/system';

import Warning from 'errors/Warning';
import Info from 'errors/Info';
import FetchError from 'errors/FetchError';

export interface ErrorDisplayProps {
  children?: ReactNode;
  sx?: SxProps;
  error: Error;
}

function severity(error: Error): 'error' | 'warning' | 'info' {
  if (error instanceof Warning) {
    return 'warning';
  }

  if (error instanceof Info) {
    return 'info';
  }

  if (error instanceof FetchError) {
    return 'warning';
  }

  return 'error';
}

function ErrorDisplay({ sx, error, children }: ErrorDisplayProps) {
  const { name, message } = error;

  return (
    <Alert severity={severity(error)} sx={sx}>
      <AlertTitle>{name}</AlertTitle>
      <div>{message}</div>
      {children && <div>{children}</div>}
    </Alert>
  );
}

export default ErrorDisplay;
