import { createContext, ReactNode, useContext, useEffect, useRef } from 'react';

const DateContext = createContext<Date>(new Date());

function getDate(): Date {
  if (process.env.REACT_APP_OVERWRITE_DATE) {
    return new Date(parseInt(process.env.REACT_APP_OVERWRITE_DATE, 10));
  }

  return new Date();
}

export function DateProvider({ children }: { children?: ReactNode }) {
  const date = useRef<Date>(getDate());

  useEffect(() => {
    const interval = setInterval(() => {
      date.current = getDate();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <DateContext.Provider value={date.current}>{children}</DateContext.Provider>;
}

export function useCurrentDate(): Date {
  return useContext(DateContext);
}
