import { useState } from 'react';

import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';

import useIsSmall from 'hooks/useIsSmall';

import useTranslate from './useTranslate';
import SearchModal from './SearchModal';

function SearchToggle() {
  const t = useTranslate();
  const isSmall = useIsSmall();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {isModalOpen && <SearchModal isOpen onClose={() => setIsModalOpen(false)} />}
      {isSmall ? (
        <IconButton size="small" onClick={() => setIsModalOpen(true)}>
          <SearchIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          startIcon={<SearchIcon />}
          color="inherit"
          size="medium"
          onClick={() => setIsModalOpen(true)}
        >
          {t('placeholder')}
        </Button>
      )}
    </>
  );
}

export default SearchToggle;
