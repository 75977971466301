import { parse } from 'query-string';

export function createLoginPath(base: string, location?: Location): string {
  if (location) {
    // if pathname is same as base, don't append return path
    if (location.pathname === base) {
      return base;
    }

    // if location already has return path, pass it on
    if ((location.search || '').indexOf('?return=') === 0) {
      return `${base}${location.search}`;
    }

    // add location path and query string to base by default
    return `${base}?return=${location.pathname || ''}${location.search || ''}`;
  }

  // no location provided
  return base;
}

export function createReturnPath(location: Location): string {
  const data = parse(location.search);

  if (data.return) {
    return Array.isArray(data.return) ? data.return.join('') : data.return;
  }

  return '/';
}

export function returnPath(): string {
  return createReturnPath(window.location);
}

export function loginPath(base = '/user/login'): string {
  return createLoginPath(base, window.location);
}
