import { useContext } from 'react';

import { UserContext, UserContextProps } from '../UserContext';

const REQUIRE_AUTH = process.env.REACT_APP_REQUIRE_AUTH === 'true';

interface UseUserResponse extends UserContextProps {
  isLoggedIn: boolean;
  isLoading: boolean;
  requireAuth: boolean;
}

function useUser(): UseUserResponse {
  const userContext = useContext(UserContext);

  const isLoggedIn = !userContext.isLoading && !!userContext.user;
  const requireAuth = REQUIRE_AUTH && !isLoggedIn;

  return {
    ...userContext,
    isLoading: userContext.isLoading,
    isLoggedIn,
    requireAuth,
  };
}

export default useUser;
