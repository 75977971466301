import { lazy, ReactNode, useEffect } from 'react';
import { useParams } from 'react-router';

import useAreas from 'data/areas/hooks/useAreas';

const NotFound = lazy(() => import('../NotFound/NotFound'));

function SubdirectoryHandler({ children }: { children: ReactNode }) {
  const { subdirectory } = useParams();
  const { isSubDirMode, subdirectory: currentSubdirectory, selected, setSubdirectory } = useAreas();

  useEffect(() => {
    if (!isSubDirMode || currentSubdirectory === subdirectory) {
      return;
    }

    setSubdirectory(subdirectory);
  }, [subdirectory, currentSubdirectory, setSubdirectory, isSubDirMode]);

  if (isSubDirMode && currentSubdirectory && !selected) {
    return <NotFound />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default SubdirectoryHandler;
