import { SyntheticEvent, useCallback, useState } from 'react';
import { useLocation, matchPath, Location } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import basePath from 'utils/basePath';

import useCreatePath from 'hooks/useCreatePath';
import useIsSmall from 'hooks/useIsSmall';

import useTranslate from '../../hooks/useTranslate';

import Homepage from './pages/Homepage';
import Maps from './pages/Maps';
import Details from './pages/Details';
import Explorer from './pages/Explorer';

function matchHelpSection(location: Location, createPath: (path: string) => string) {
  if (matchPath(basePath, location.pathname)) {
    return 'homepage';
  }

  if (matchPath(createPath('map'), location.pathname) || matchPath('/locations/:id/map', location.pathname)) {
    return 'maps';
  }

  if (matchPath(createPath('locations/:id/explorer'), location.pathname)) {
    return 'explorer';
  }

  if (matchPath(createPath('locations/:id/*'), location.pathname)) {
    return 'details';
  }

  return false;
}

interface HelpModalContentProps {
  onClose: () => void;
}

function HelpModalContent({ onClose }: HelpModalContentProps) {
  const createPath = useCreatePath();
  const location = useLocation();
  const [expanded, setExpanded] = useState<string | false>(matchHelpSection(location, createPath));
  const isSmall = useIsSmall();
  const t = useTranslate();

  const handleChange = useCallback(
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    },
    [],
  );

  const heightOffset = isSmall ? '300px' : '400px';

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        mt: isSmall ? 0 : 6,
        left: '50%',
        transform: 'translateX(-50%)',
        width: isSmall ? '100%' : '90%',
        height: isSmall ? '100vh' : undefined,
        maxWidth: 600,
      }}
    >
      <Paper
        elevation={8}
        sx={{
          height: isSmall ? '100vh' : undefined,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 1,
            alignItems: 'center',
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
          }}
        >
          <Typography variant="subtitle2">{t('help.modal_title')}</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ p: 0 }}>
          <Accordion
            sx={{ '&:before': { opacity: '1!important' } }}
            elevation={0}
            expanded={expanded === 'homepage'}
            onChange={handleChange('homepage')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>{t('help.home')}</AccordionSummary>
            <AccordionDetails sx={{ maxHeight: `calc(100vh - ${heightOffset})`, overflowY: 'auto' }}>
              <Homepage />
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ '&:before': { opacity: '1!important' } }}
            elevation={0}
            expanded={expanded === 'maps'}
            onChange={handleChange('maps')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>{t('help.map')}</AccordionSummary>
            <AccordionDetails sx={{ maxHeight: `calc(100vh - ${heightOffset})`, overflowY: 'auto' }}>
              <Maps />
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ '&:before': { opacity: '1!important' } }}
            elevation={0}
            expanded={expanded === 'details'}
            onChange={handleChange('details')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>{t('help.details')}</AccordionSummary>
            <AccordionDetails sx={{ maxHeight: `calc(100vh - ${heightOffset})`, overflowY: 'auto' }}>
              <Details />
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ '&:before': { opacity: '1!important' } }}
            elevation={0}
            expanded={expanded === 'explorer'}
            onChange={handleChange('explorer')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>{t('help.history')}</AccordionSummary>
            <AccordionDetails sx={{ maxHeight: `calc(100vh - ${heightOffset})`, overflowY: 'auto' }}>
              <Explorer />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Paper>
    </Box>
  );
}

export default HelpModalContent;
