import { useCallback } from 'react';

import useAreas from 'data/areas/hooks/useAreas';

function useCreatePath() {
  const { subdirectory } = useAreas();

  return useCallback(
    (path = ''): string => [subdirectory ? `/${subdirectory}` : undefined, path].join('/'),
    [subdirectory],
  );
}

export default useCreatePath;
