class FetchError extends Error {
  name: string;

  status: number;

  statusText: string;

  constructor(message: string, status: number, statusText: string, name = 'FetchError') {
    super(message);

    this.name = name;
    this.status = status;
    this.statusText = statusText;
  }
}

export default FetchError;
