import { ChangeEvent, useCallback } from 'react';

import { NativeSelect } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import useAppStorage from 'data/app/useAppStorage';

import useTranslate from '../../hooks/useTranslate';

export interface LanguageSwitcherProps {
  isMobile?: boolean;
}

function LanguageSwitcher({ isMobile }: LanguageSwitcherProps) {
  const t = useTranslate();
  const { lang, setLang } = useAppStorage();

  const handleLanguageChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;

      if (value === 'en' || value === 'nb') {
        setLang(value);
      }
    },
    [setLang],
  );

  const norwegianShort = t('norwegian_short');
  const norwegian = t('norwegian');
  const englishShort = t('english_short');
  const english = t('english');

  return (
    <FormControl
      sx={{
        width: '100%',
        minWidth: isMobile ? '53px' : '120px',
      }}
    >
      <NativeSelect
        value={lang}
        onChange={handleLanguageChange}
        inputProps={{
          name: 'lang',
          id: 'language-switcher',
        }}
      >
        <option value="nb">{isMobile ? norwegianShort : norwegian}</option>
        <option value="en">{isMobile ? englishShort : english}</option>
      </NativeSelect>
    </FormControl>
  );
}

export default LanguageSwitcher;
