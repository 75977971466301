import { useContext } from 'react';

import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import useAreas from 'data/areas/hooks/useAreas';

import PageSettingsContext from 'modules/App/PageSettingsContext';

import Copyright from './components/Copyright';
import Wave from './components/Wave';
import useTranslate from './hooks/useTranslate';

function Footer() {
  const t = useTranslate();

  const {
    value: { disableFooter },
  } = useContext(PageSettingsContext);

  const { isSubDirMode } = useAreas();

  if (disableFooter) return null;

  return (
    <Box
      className="hide-on-print"
      sx={{
        '& a': {
          color: 'text.primary',
        },
      }}
    >
      <Box
        sx={{
          background: (theme) => theme.palette.grey[50],
          height: (theme) => ({
            xs: theme.spacing(3),
            sm: theme.spacing(8),
          }),
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 0,
          height: (theme) => ({
            xs: theme.spacing(5),
            sm: theme.spacing(isSubDirMode ? 5 : 20),
          }),
          position: 'relative',
          overflow: 'hidden',
          background: (theme) => `linear-gradient(
            to bottom, 
            ${theme.palette.grey[50]} 0%,
            ${theme.palette.background.default} 100%
          );`,
        }}
      >
        {!isSubDirMode && (
          <Box
            sx={{
              minWidth: { sm: 1900, xs: 1000 },
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <Wave />
          </Box>
        )}
      </Box>
      <Container
        sx={{
          mt: {
            xs: 3,
            sm: 0,
          },
          mb: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
            }}
          >
            <Copyright />
            <Box sx={{ mx: 2, display: { xs: 'none', sm: 'block' } }}>•</Box>
            <Link
              href="https://policies.intoto.io/"
              rel="noreferrer"
              underline="hover"
              sx={{
                my: {
                  xs: 1,
                  sm: 0,
                },
                mt: {
                  xs: 2,
                  sm: 0,
                },
              }}
            >
              <Typography variant="body2">{t('policies')}</Typography>
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
