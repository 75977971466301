import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useUser from 'data/users/hooks/useUser';

import { loginPath } from 'utils/loginPath';

interface ProtectedRouteProps {
  children: ReactNode;
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { requireAuth } = useUser();
  const location = useLocation();

  if (requireAuth) {
    return <Navigate to={loginPath()} state={{ from: location }} replace />;
  }

  return <>{children}</>; // eslint-disable-line react/jsx-no-useless-fragment
}

export default ProtectedRoute;
