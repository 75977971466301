import { createTheme } from '@mui/material';

const font = `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`;

const T = createTheme({
  palette: {
    primary: {
      main: '#06f',
      light: '#6b93ff',
      dark: '#003dcb',
    },
    secondary: {
      main: '#ffa726',
      light: '#ffd95b',
      dark: '#c77800',
    },
    grey: {
      50: '#f2f7ff',
    },
  },
  typography: {
    fontFamily: font,
    fontSize: 15,
    subtitle2: {
      fontSize: '1.15em',
      fontWeight: 'bold',
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 5,
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          height: '64px',
          minHeight: '64px',
          '@media (min-width: 600px)': {
            minHeight: '64px',
          },
        },
      },
    },
  },
});

T.shadows[5] = `0px 3px 5px -1px rgba(0,0,0,0.05),
0px 5px 8px 0px rgba(0,0,0,0.045),
0px 1px 14px 0px rgba(0,0,0,0.04)`;

T.shadows[9] = '0 12px 12px 0 rgba(0,0,0,0.025)';

export default T;
