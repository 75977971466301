function pocStats(station: IntotoStation): Promise<IntotoStation> {
  return import(`./mock/${station.id}/stats.json`)
    .then((result: { default: StationStats }) => ({
      ...station,
      stats: result.default,
    }))
    .catch(() => station);
}

export default pocStats;
