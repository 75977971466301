import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { format } from 'date-fns';

import useAppStorage from 'data/app/useAppStorage';

import { fetchWhatsNew } from '../api';

interface WhatsNewContent {
  newItemsCount: number;
  error: Error | null;
  items?: WhatsNew[];
  isLoading: boolean;
}

function toReadDateString(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

function useWhatsNew(currentItem?: number, isOpened?: boolean): WhatsNewContent {
  const { lang, readItems, setReadItem } = useAppStorage();

  const url = lang === 'en' ? process.env.REACT_APP_WHATS_NEW_EN : process.env.REACT_APP_WHATS_NEW_NB;

  const { isLoading, error, data } = useQuery<WhatsNew[], Error>(['whatsnew', lang], () => {
    if (!url) {
      throw new Error('Invalid URL');
    }

    return fetchWhatsNew(url);
  });

  const currentDate = useMemo((): string => {
    if (typeof currentItem !== 'undefined' && data && isOpened) {
      return toReadDateString(data[currentItem].date);
    }

    return '';
  }, [currentItem, data, isOpened]);

  useEffect(() => {
    if (currentDate !== '') {
      setReadItem(currentDate);
    }
  }, [currentDate, setReadItem]);

  const newItemsCount = useMemo(() => {
    if (!data || data.length === 0) {
      return 0;
    }

    return data.filter((item) => !readItems.includes(toReadDateString(item.date))).length;
  }, [data, readItems]);

  return {
    isLoading,
    error,
    newItemsCount,
    items: data,
  };
}

export default useWhatsNew;
