import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useAppStorage from 'data/app/useAppStorage';

function Homepage() {
  const { lang } = useAppStorage();

  if (lang === 'nb') {
    return (
      <Box>
        <Typography variant="subtitle2">Mine steder</Typography>
        <Typography variant="body1" my={1}>
          Viser en liste over lokasjoner du har favorisert, for hurtig tilgang.
        </Typography>
        <Typography variant="body1" my={1}>
          Du kan legge til dine egne ved å velge favoriser/stjerne på en lokasjon.
        </Typography>
        <Typography variant="body1" my={1}>
          Hvis du ikke har valgt noen, vises det generelle lokasjoner.
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          Endringer i vannivå og trend
        </Typography>
        <Typography variant="body1" my={1}>
          Endringer siste time og siste 24 timer vises pr. lokasjon i tabellen.
        </Typography>
        <Typography variant="body1" my={1}>
          Grafen for siste 48 viser en trend for relativ endring.
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          Fargeindikatorer
        </Typography>
        <Typography variant="body1" my={1}>
          Tydelig Intoto-blå betyr stabile forhold med lite endringer i vannivå.
        </Typography>
        <Typography variant="body1" my={1}>
          Lyseblå betyr merkbar nedgang, og rosa betyr merkbar økning.
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          Definer din posisjon
        </Typography>
        <Typography variant="body1" my={1}>
          Hvis du kobler din fysiske posisjon, vil sidene vise avstand mellom deg og lokasjonene.
        </Typography>
        <Typography variant="body1" my={1}>
          Dette gjøres via meny oppe til høyre og innstillinger for posisjon.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="subtitle2">My Locations</Typography>
      <Typography variant="body1" my={1}>
        This lists stations you have favourited and last visited, for quick access.
      </Typography>
      <Typography variant="body1" my={1}>
        You can add locations to your favourites by clicking the star.
      </Typography>
      <Typography variant="body1" my={1}>
        If you have no favourites, it will show generic stations.
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Change in water level and trends
      </Typography>
      <Typography variant="body1" my={1}>
        {`We display the last 1 hour and 24 hour change in water level next to 
        the station's name.`}
      </Typography>
      <Typography variant="body1" my={1}>
        The line under trend displays the relative change in water level the last 48 hours.
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Water level colour indications
      </Typography>
      <Typography variant="body1" my={1}>
        {`Values in dark blue are stable, light blue means the level lowered too much, red 
        means water level raised too much.`}
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Setting your physical location
      </Typography>
      <Typography variant="body1" my={1}>
        {`If you set your physical location Studio will show the relative positions between you
        and the stations.`}
      </Typography>
      <Typography variant="body1" my={1}>
        Settings on your location you can changed in menu on the top right.
      </Typography>
    </Box>
  );
}

export default Homepage;
