import i18n, { Namespace } from 'i18next';
import { useTranslation, UseTranslationResponse } from 'react-i18next';

interface LanguageResource {
  [key: string]: string | LanguageResource;
}

function addResourceBundle(lang: AppLang, namespace: string, resources: LanguageResource): void {
  i18n.addResourceBundle(lang, namespace, resources);
}

function addTranslationNamespace(namespace: string, english: LanguageResource, norwegian: LanguageResource): void {
  addResourceBundle('en', namespace, english);
  addResourceBundle('nb', namespace, norwegian);
}

function createUseTranslate<N extends Namespace>(
  namespace: N,
  english: LanguageResource,
  norwegian: LanguageResource,
): () => UseTranslationResponse<N, undefined>['t'] {
  addTranslationNamespace(namespace as string, english, norwegian);

  function useTranslate(): UseTranslationResponse<N, undefined>['t'] {
    const { t } = useTranslation<N>(namespace);

    return t;
  }

  return useTranslate;
}

export default createUseTranslate;
