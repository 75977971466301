import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { langStorage } from 'data/app/AppStorageContext';

const resources = {
  en: {},
  nb: {},
};

export default i18n.use(initReactI18next).init({
  resources,
  lng: langStorage.get(),
  fallbackLng: 'nb',
  returnNull: false,
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      if (format === 'dateFull' || format === 'dateShort') {
        const formattedDate = new Intl.DateTimeFormat(lng, {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }).format(value);

        const formattedTime = new Intl.DateTimeFormat(lng, {
          hour: 'numeric',
          minute: 'numeric',
        }).format(value);

        return format === 'dateFull' ? `${formattedDate} ${formattedTime}` : formattedDate;
      }

      if (format === 'numberFormat') {
        return new Intl.NumberFormat(lng).format(value);
      }

      return value;
    },
  },
});
