function pocData(area: Area): Promise<Area> {
  return import(`./mock/${area.id}/data.json`)
    .then((result: { default: Partial<Area> }) => ({
      ...area,
      ...result.default,
    }))
    .catch(() => area);
}

export default pocData;
