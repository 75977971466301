import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useAppStorage from '../data/app/useAppStorage';

const propertyID = process.env.REACT_APP_GOOGLE_ANALYTICS || '';

const usePageTracking = (): void => {
  const location = useLocation();

  const { cookieConsent } = useAppStorage();

  const pageView = useCallback((page: string): void => {
    window.gtag('event', 'page_view', {
      page_location: window.location.origin,
      page_path: page,
      send_to: propertyID,
    });
  }, []);

  useEffect(() => {
    if (propertyID && cookieConsent === 'accepted') {
      window.gtag('js', new Date());
      window.gtag('config', propertyID, { send_page_view: false });

      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${propertyID}`;
      document.body.appendChild(script);
    }
  }, [cookieConsent]);

  useEffect(() => {
    pageView(location.pathname + location.search);
  }, [location, pageView]);
};

export default usePageTracking;
