import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useAppStorage from 'data/app/useAppStorage';

function Details() {
  const { lang } = useAppStorage();

  if (lang === 'nb') {
    return (
      <Box>
        <Typography variant="subtitle2">Oversikt</Typography>
        <Typography variant="body1" my={1}>
          Denne siden inneholder basis informasjon om lokasjonen.
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          Endringer i nivå og trend
        </Typography>
        <Typography variant="body1" my={1}>
          Vi viser endringer siste time og siste 24 timer.
        </Typography>
        <Typography variant="body1" my={1}>
          Siste 48 timer vises som en relativ trend.
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          Graf og tabell
        </Typography>
        <Typography variant="body1" my={1}>
          Grafen viser målinger fra siste 24 timer. Ved å holde musemarkøren over grafen, vil man se mer detaljert
          informasjon om gitt tidspunkt.
        </Typography>
        <Typography variant="body1" my={1}>
          Tabellen viser gjennomsnittlige målinger i 3 timers intervall: natt, morgen, ettermiddag og kveld.
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          Værforhold
        </Typography>
        <Typography variant="body1" my={1}>
          Værmelding på respektiv lokasjon.
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          I nærheten
        </Typography>
        <Typography variant="body1" my={1}>
          Viser lokasjoner som er nærme i forhold til lokasjonssiden du er på.
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          Snitt profil
        </Typography>
        <Typography variant="body1" my={1}>
          Når en lokasjon har en kjent snitt profil, vises denne. Snitt profilen vise en visuell seksjon av lokasjonen.
        </Typography>
        <Typography variant="body1" my={1}>
          Da viser vannivå samt areal av snittet.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="subtitle2">The overview</Typography>
      <Typography variant="body1" my={1}>
        This page contains the most important information there is to know about this station.
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Change in water level and trends
      </Typography>
      <Typography variant="body1" my={1}>
        {`We display the last 1 hour and 24 hour change in water level next to 
        the station's name.`}
      </Typography>
      <Typography variant="body1" my={1}>
        The line under trend displays the relative change in water level the last 48 hours.
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Graph and table
      </Typography>
      <Typography variant="body1" my={1}>
        The graph shows the known measurements the last 24 hours. Hover over the graph for detailed information about
        the measurement at that moment in time.
      </Typography>
      <Typography variant="body1" my={1}>
        The table shows the average water level of the morning, afternoon, evening, and night respectively.
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Weather report
      </Typography>
      <Typography variant="body1" my={1}>
        See the current weather report at the station&apos;s location.
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Nearby locations
      </Typography>
      <Typography variant="body1" my={1}>
        A list of stations which are closest to the current station which is in view.
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Cross section
      </Typography>
      <Typography variant="body1" my={1}>
        When the station has a known cross section it will be shown at the top of the page. The cross section shows a
        cross section of the river bed of where the station is measuring.
      </Typography>
      <Typography variant="body1" my={1}>
        It displays the current water level and area of water of the current water level.
      </Typography>
    </Box>
  );
}

export default Details;
