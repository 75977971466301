import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import basePath from 'utils/basePath';

import ProtectedRoute from './ProtectedRoute';
import SubdirectoryHandler from './SubdirectoryHandler';

const HomepageResolver = lazy(() => import('./HomepageResolver'));
const LocationRoutes = lazy(() => import('../Locations/Routes'));
const MapPage = lazy(() => import('../Map/MapPage'));
const NotFound = lazy(() => import('../NotFound/NotFound'));
const ComparePage = lazy(() => import('../Compare/Compare'));
const UserRoutes = lazy(() => import('../User/Routes'));

function AppRoutes() {
  return (
    <Routes>
      <Route
        path={basePath}
        element={
          <Suspense>
            <SubdirectoryHandler>
              <Routes>
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <HomepageResolver />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="locations/*"
                  element={
                    <ProtectedRoute>
                      <LocationRoutes />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="map/*"
                  element={
                    <ProtectedRoute>
                      <MapPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="compare/*"
                  element={
                    <ProtectedRoute>
                      <ComparePage />
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </SubdirectoryHandler>
          </Suspense>
        }
      />
      <Route
        path="user/*"
        element={
          <Suspense>
            <UserRoutes />
          </Suspense>
        }
      />
    </Routes>
  );
}

export default AppRoutes;
