import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import StarIcon from '@mui/icons-material/Star';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import formatStationName from 'utils/formatStationName';

interface SearchResultItemProps {
  type?: 'starred' | 'visited' | 'default';
  selected?: boolean;
  station: Station;
  navigate: (station: Station) => void;
}

function SearchResultItem({ station, navigate, type = 'default', selected = false }: SearchResultItemProps) {
  return (
    <ListItemButton sx={{ p: 2 }} onClick={() => navigate(station)} selected={selected}>
      {type !== 'default' && (
        <ListItemIcon sx={{ minWidth: 32 }}>
          {type === 'starred' && <StarIcon fontSize="small" />}
          {type === 'visited' && <AccessTimeIcon fontSize="small" />}
        </ListItemIcon>
      )}
      {formatStationName(station)}
    </ListItemButton>
  );
}

export default SearchResultItem;
