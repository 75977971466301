import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useAppStorage from 'data/app/useAppStorage';

function Explorer() {
  const { lang } = useAppStorage();

  if (lang === 'nb') {
    return (
      <Box>
        <Typography variant="subtitle2">Historikk</Typography>
        <Typography variant="body1" my={1}>
          Her kan du hente detaljerte målinger fra ønskede tidsrom.
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          Filter og detaljgrad
        </Typography>
        <Typography variant="body1" my={1}>
          Velg datoen eller måneden du ønsker å se oppe til venstre.
        </Typography>
        <Typography variant="body1" my={1}>
          Velg detaljgrad du ønsker, pr. minutt / time / døgn.
        </Typography>
        <Typography variant="body1" my={1}>
          Når målingene er fra vises i tabell som genereres.
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          Data visualisering
        </Typography>
        <Typography variant="body1" my={1}>
          Du kan fremvise målingene i en tabell eller graf.
        </Typography>
        <Typography variant="body1" my={1}>
          Du kan navigere i bunnen av sidene ved større tabeller.
        </Typography>

        <Typography variant="subtitle2" mt={3}>
          Eksport
        </Typography>
        <Typography variant="body1" my={1}>
          Hvis du er i tabell-visning, vil du få en mulighet til å eksportere data til .CSV format.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="subtitle2">Explorer</Typography>
      <Typography variant="body1" my={1}>
        {`On this page you can view detailed information about observation data
        measured on this station.`}
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Filters and detail level
      </Typography>
      <Typography variant="body1" my={1}>
        {`Select the level of detail you want to see in the top centre bar for daily,
        hourly, or minutely data.`}
      </Typography>
      <Typography variant="body1" my={1}>
        All data points are averages for the date (and time) specified in the left column.
      </Typography>
      <Typography variant="body1" my={1}>
        Select the date or month you want to see on the top left.
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Data visualisation
      </Typography>
      <Typography variant="body1" my={1}>
        You can view the data gathered in a table or drawn in a graph.
      </Typography>
      <Typography variant="body1" my={1}>
        {`You can navigate the data in the graph by moving or expanding 
        the navigator at the bottom of the graph.`}
      </Typography>

      <Typography variant="subtitle2" mt={3}>
        Export
      </Typography>
      <Typography variant="body1" my={1}>
        When in table view mode, you will get an button for exporting data to .CSV format.
      </Typography>
    </Box>
  );
}

export default Explorer;
