import { useCallback, useState } from 'react';

import { AppStorage } from 'utils/createAppStorage';

function useAppStorageState<T>(storage: AppStorage<T>): [T, (newValue: T) => void] {
  const [value, setValue] = useState<T>(storage.get());

  const setNewValue = useCallback(
    (newValue: T) => {
      setValue(storage.set(newValue));
    },
    [storage],
  );

  return [value, setNewValue];
}

export default useAppStorageState;
