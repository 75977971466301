import { ReactNode } from 'react';

import Badge from '@mui/material/Badge';

import useWhatsNew from '../hooks/useWhatsNew';

interface WhatsNewBadgeProps {
  children?: ReactNode;
  hide?: boolean;
}

function WhatsNewBadge({ hide, children }: WhatsNewBadgeProps) {
  const { newItemsCount, isLoading, error } = useWhatsNew();

  return (
    <Badge
      badgeContent={newItemsCount}
      invisible={newItemsCount === 0 || hide || isLoading || Boolean(error)}
      color="error"
      className="WhatsNewBadge"
      sx={{
        '& .MuiBadge-badge': {
          transform: 'scale(1)',
        },
        '& .MuiBadge-invisible': {
          transform: 'scale(0)',
        },
      }}
    >
      {children}
    </Badge>
  );
}

export default WhatsNewBadge;
