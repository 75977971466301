import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider as MUIThemeProvider } from '@mui/system';
import { ReactQueryDevtools } from 'react-query/devtools';

// setup i18n before the rest
import setupi18next from 'locales/i18n-setup';

import UserContextProvider from 'data/users/UserContext';
import SocketContextProvider from 'data/sockets/SocketContext';
import AppStorageContextProvider from 'data/app/AppStorageContext';
import AreasContextProvider from 'data/areas/AreasContext';
import StationsStorageContextProvider from 'data/stations/StationsStorageContext';
import ObservationSettingsContextProvider from 'data/observations/ObservationSettingsContext';

import App from 'modules/App/App';
import Theme from 'modules/App/Theme';

const reactDom = (target: Element) => {
  const queryClient = new QueryClient();
  const enableReactQueryDevTools =
    process.env.REACT_APP_ENABLE_RQ_DEV_TOOLS === 'true' && process.env.NODE_ENV === 'development';
  const root = createRoot(target);

  setupi18next.then(() => {
    root.render(
      <QueryClientProvider client={queryClient}>
        <AppStorageContextProvider>
          <AreasContextProvider>
            <StationsStorageContextProvider>
              <ObservationSettingsContextProvider>
                <SocketContextProvider>
                  <UserContextProvider>
                    {enableReactQueryDevTools && <ReactQueryDevtools initialIsOpen={false} />}
                    <MUIThemeProvider theme={Theme}>
                      <Router>
                        <App />
                      </Router>
                    </MUIThemeProvider>
                  </UserContextProvider>
                </SocketContextProvider>
              </ObservationSettingsContextProvider>
            </StationsStorageContextProvider>
          </AreasContextProvider>
        </AppStorageContextProvider>
      </QueryClientProvider>,
    );
  });
};

const target = document.querySelector('#root');

if (target) {
  reactDom(target);
}
