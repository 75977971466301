import { ReactNode } from 'react';

import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import FullHeightContainer from 'components/FullHeightContainer/FullHeightContainer';
import Loading from 'components/Loading/Loading';
import ConsentBar from 'components/ConsentBar/LoadableConsentBar';

import useUser from 'data/users/hooks/useUser';
import useAppStorage from 'data/app/useAppStorage';

import usePageTracking from 'hooks/usePageTracking';

import Header from 'modules/Header/Header';
import Footer from 'modules/Footer/Footer';

import { PageSettingsContextProvider } from './PageSettingsContext';

import ScrollToTop from './ScrollToTop';
import Routes from './Routes';

import { DateProvider } from './DateContext';
import './App.css';

interface FCWithChildren {
  children?: ReactNode;
}

function HeaderWrap({ children }: FCWithChildren) {
  return <header>{children}</header>;
}

function MainWrap({ children }: FCWithChildren) {
  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
      }}
    >
      {children}
    </Box>
  );
}

function FooterWrap({ children }: FCWithChildren) {
  return <footer>{children}</footer>;
}

function App() {
  const { locale } = useAppStorage();
  const { isLoading } = useUser();

  usePageTracking();

  if (isLoading) {
    return (
      <FullHeightContainer>
        <Loading />
      </FullHeightContainer>
    );
  }

  return (
    <DateProvider>
      <ScrollToTop />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <PageSettingsContextProvider>
          <FullHeightContainer>
            <HeaderWrap>
              <Header />
            </HeaderWrap>

            <MainWrap>
              <Routes />
            </MainWrap>

            <FooterWrap>
              <Footer />
            </FooterWrap>

            <ConsentBar />
          </FullHeightContainer>
        </PageSettingsContextProvider>
      </LocalizationProvider>
    </DateProvider>
  );
}

export default App;
