import { useEffect, useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';

function useIsSmall(selector?: string): boolean {
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

  useEffect(() => {
    const container = selector ? document.querySelector(selector) : null;

    if (!container) {
      return () => undefined;
    }

    function handleResize() {
      if (!container) return;

      const boundingBox = container.getBoundingClientRect();
      const { width, height } = boundingBox;

      setDimensions({ width, height });
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [selector]);

  if (!selector) {
    return isSmallBreakpoint;
  }

  return dimensions.width <= 600;
}

export default useIsSmall;
