import { Record, String, Optional } from 'runtypes';

import { getNonApi } from 'utils/fetch';

const NoticeRecord = Record({
  url: Optional(String),
  text: String,
});

export function fetchNotice(url: string): Promise<Notice> {
  return getNonApi(url).then((data) => NoticeRecord.check(data));
}
